<template>
    <div>
		<v-dialog
			v-model="showDialogTeam"
			transition="dialog-top-transition"
			persistent
			width="100%"
			:fullscreen="$vuetify.breakpoint.mobile"
		>		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    Change Teams
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>
                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Drafting Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >                                            
                                            <v-list-item-group
                                                v-model="drafterTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemDrafter, index) in listDrafters"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemDrafter.userName }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Permit Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="permitTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemPermit, index) in listPermit"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemPermit.userName }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Electric Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="electricTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemTechTeam, index) in listElectricTech"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemTechTeam.name }}</v-list-item-title>
                                                            
                                                            <v-text-field
                                                                v-if="itemTechTeam.id == -1 && active"
                                                                v-model="electricTeamOtherName" 
                                                                single-line
                                                                outlined
                                                                dense
                                                                @keyup.prevent=""
                                                                @click.stop=""
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Plumbing Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="plumbingTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemTechTeam, index) in listPlumbingTech"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemTechTeam.name }}</v-list-item-title>
                                                            
                                                            <v-text-field
                                                                v-if="itemTechTeam.id == -1 && active"
                                                                v-model="plumbingTeamOtherName" 
                                                                single-line
                                                                outlined
                                                                dense
                                                                @keyup.prevent=""
                                                                @click.stop=""
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Gas Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="gasTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemTechTeam, index) in listGasTech"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemTechTeam.name }}</v-list-item-title>
                                                            
                                                            <v-text-field
                                                                v-if="itemTechTeam.id == -1 && active"
                                                                v-model="gasTeamOtherName" 
                                                                single-line
                                                                outlined
                                                                dense
                                                                @keyup.prevent=""
                                                                @click.stop=""
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>
                    </v-row>

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 10px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel buttonsHigher"
							outlined
							@click="cancel()"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 buttonsHigher"
							outlined
							@click="saveDraterTeam()"
						>
							Save
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import { 
        CATEGORY_TEAM, 
        CONTRACTOR_CATEGORY, 
    } from "@/utilities/Enums";

	export default {

        mixins: [Helpers],

        props: {
			
            idAux: {
                type: Number,
                default: 0
            },
			
            idServiceProject: {
                type: Number,
                default: 0
            },

			showDialogTeam: {
				default: false
			},
		},

        watch: {
            async "idAux"() {
                await this.listAvailableTechs();
				await this.getRegisters();
            },
        },
		
        data: () => ({
            
            listDrafters: [],
            drafterTeam: [],
            listPermit: [],
            permitTeam: [],
            listElectricTech: [],
            electricTeam: [],
            electricTeamOtherName: '',
            listPlumbingTech: [],
            plumbingTeam: [],
            plumbingTeamOtherName: '',
            listGasTech: [],
            gasTeam: [],
            gasTeamOtherName: '',
		}),

        methods: {

            cancel() {
                this.$emit('update:showDialogTeam', false);
            },

            async listAvailableTechs() {
                this.listDrafters = await this.$store.dispatch("serviceModule/ListAvaliableDrafters");
                this.listPermit = await this.$store.dispatch("serviceModule/ListAvaliablePermitters");
                
                const listContractors = await this.$store.dispatch("contractorModule/List");

                const otherOption = {
                    id: -1,
                    name: "Other"
                }

                this.listElectricTech = listContractors.filter(item => item.idContractorCategory == CONTRACTOR_CATEGORY.Electric.value);
                this.listPlumbingTech = listContractors.filter(item => item.idContractorCategory == CONTRACTOR_CATEGORY.Plumbing.value);
                this.listGasTech = listContractors.filter(item => item.idContractorCategory == CONTRACTOR_CATEGORY.Gas.value);

                this.listElectricTech.push(otherOption);
                this.listPlumbingTech.push(otherOption);
                this.listGasTech.push(otherOption);
            },

            async getRegisters() {

                const listTeam = await this.$store.dispatch("serviceModule/GetTeam", this.idServiceProject);

                const listTeamDrafter = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.DRAFTER.value);
                const listTeamPermit = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.PERMITTECH.value);
                const listTeamElectric = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.ELECTRICTECH.value);
                const listTeamPlumbing = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.PLUMBINGTECH.value);
                const listTeamGas = listTeam.filter(item => item.categoryTeam == CATEGORY_TEAM.GASTECH.value);

                this.drafterTeam = [];

                if (listTeam.length > 0) {
                    let index = 0;
                    this.listDrafters.forEach ( itemTeam => {

                        const checkIsUserIncluded = listTeamDrafter.filter(team => team.idUserOrContractor == itemTeam.id)[0];
                        if (checkIsUserIncluded != null && checkIsUserIncluded != undefined) {
                            this.drafterTeam.push(index);
                        }

                        index++;
                    })

                    index = 0;
                    this.listPermit.forEach ( itemTeam => {

                        const checkIsUserIncluded = listTeamPermit.filter(team => team.idUserOrContractor == itemTeam.id)[0];
                        if (checkIsUserIncluded != null && checkIsUserIncluded != undefined) {
                            this.permitTeam.push(index);
                        }

                        index++;
                    })

                    index = 0;
                    this.listElectricTech.forEach ( itemTeam => {

                        const checkIsUserIncluded = listTeamElectric.filter(team => team.idUserOrContractor == itemTeam.id)[0];
                        if (checkIsUserIncluded != null && checkIsUserIncluded != undefined) {
                            this.electricTeam.push(index);

                            if (itemTeam.id == -1) {
                                this.electricTeamOtherName = checkIsUserIncluded.nameOther;
                            }
                        }

                        index++;
                    })

                    index = 0;
                    this.listPlumbingTech.forEach ( itemTeam => {

                        const checkIsUserIncluded = listTeamPlumbing.filter(team => team.idUserOrContractor == itemTeam.id)[0];
                        if (checkIsUserIncluded != null && checkIsUserIncluded != undefined) {
                            this.plumbingTeam.push(index);

                            if (itemTeam.id == -1) {
                                this.plumbingTeamOtherName = checkIsUserIncluded.nameOther;
                            }
                        }

                        index++;
                    })

                    index = 0;
                    this.listGasTech.forEach ( itemTeam => {

                        const checkIsUserIncluded = listTeamGas.filter(team => team.idUserOrContractor == itemTeam.id)[0];
                        if (checkIsUserIncluded != null && checkIsUserIncluded != undefined) {
                            this.gasTeam.push(index);

                            if (itemTeam.id == -1) {
                                this.gasTeamOtherName = checkIsUserIncluded.nameOther;
                            }
                        }

                        index++;
                    })
                }
            },

            async saveDraterTeam() {
                
                const listAllTeamsSelected = [
                    {
                        categoryTeam: CATEGORY_TEAM.DRAFTER.value,
                        listTeamSelected: [...this.drafterTeam],
                        nameOther: null,
                        list: [...this.listDrafters]
                    },

                    {
                        categoryTeam: CATEGORY_TEAM.PERMITTECH.value,
                        listTeamSelected: [...this.permitTeam],
                        nameOther: null,
                        list: [...this.listPermit]
                    },

                    {
                        categoryTeam: CATEGORY_TEAM.ELECTRICTECH.value,
                        listTeamSelected: [...this.electricTeam],
                        nameOther: this.electricTeamOtherName,
                        list: [...this.listElectricTech] 
                    },

                    {
                        categoryTeam: CATEGORY_TEAM.PLUMBINGTECH.value,
                        listTeamSelected: [...this.plumbingTeam],
                        nameOther: this.plumbingTeamOtherName,
                        list: [...this.listPlumbingTech]
                    },

                    {
                        categoryTeam: CATEGORY_TEAM.GASTECH.value,
                        listTeamSelected: [...this.gasTeam],
                        nameOther: this.gasTeamOtherName,
                        list: [...this.listGasTech] 
                    },
                ]

                let listTeamRequest = [];

                await listAllTeamsSelected.forEach(async itemTeamSelected => {

                    await itemTeamSelected.listTeamSelected.forEach(async itemTeam => {

                        const categoryTeam = itemTeamSelected.categoryTeam;
                        const idUserOrContractor = itemTeamSelected.list[itemTeam].id;
                        let nameOther = null;
                        if (idUserOrContractor == -1) {
                            nameOther = itemTeamSelected.nameOther
                        }

                        listTeamRequest.push({
                            id: 0,
                            idServiceProject: this.idServiceProject,
                            categoryTeam,
                            idUserOrContractor,
                            nameOther
                        });
                    });
                })

                const result = await this.$store.dispatch("serviceModule/AssignTeam", listTeamRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Saved with successfully");
                    this.cancel();
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }
            },
        },
    };
</script>